<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/auth-local" text=""></ion-back-button>
        </ion-buttons>
        <ion-title>
          <ion-img
            src="assets/logo-header-toolbar.svg"
            class="img-toolbar"
          ></ion-img>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" fullscreen="true">
      <form @submit.prevent="submit" class="max-width-mobile">
        <h2 style="font-weight: 500">{{ $t("Log In") }}</h2>
        <ion-item>
          <ion-label position="floating">{{ $t("E-mail") }}</ion-label>
          <ion-input
            type="email"
            v-model="form.email"
            inputmode="email"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">{{ $t("Password") }}</ion-label>
          <ion-input
            :type="showPassword ? 'text' : 'password'"
            v-model="form.password"
            class="password"
          ></ion-input>
          <ion-icon
            slot="end"
            @click="switchShowPassword"
            :icon="showPassword ? eyeOff : eye"
          ></ion-icon>
        </ion-item>
        <template v-if="errorMessage">
          <ion-chip
            v-for="(error, i) in errorMessage"
            :key="i"
            color="danger"
            style="width:calc(100% - var(--padding-inline-end, 12px));"
          >
            <ion-label v-t="error" class="ion-text-center"></ion-label>
          </ion-chip>
        </template>
        <ion-button type="submit" expand="block" size="medium">{{
          $t("Enter")
        }}</ion-button>
      </form>
      <div class="max-width-mobile">
        <p class="ion-text-center">
          <router-link to="/forgot-password">{{
            $t("Did you forget your password?")
          }}</router-link>
        </p>
        <hr />
        <h4>{{ $t("Don't have an account yet?") }}</h4>
        <p>{{ $t("Sign In now") }}</p>
        <ion-button
          @click.prevent="router.push('/register')"
          fill="outline"
          expand="block"
          >{{ $t("Create Account") }}</ion-button
        >
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonImg,
  IonItem,
  IonInput,
  IonLabel,
  IonIcon,
  IonChip,
  loadingController
} from "@ionic/vue";
import { eye, eyeOff, logInOutline } from "ionicons/icons";
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  name: "Login",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonImg,
    IonItem,
    IonInput,
    IonLabel,
    IonIcon,
    IonChip
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const showPassword = ref(false);
    const switchShowPassword = () => (showPassword.value = !showPassword.value);
    const form = reactive({
      email: null,
      password: null,
      device_name: "app"
    });
    const submit = async () => {
      const sending = await loadingController.create({
        message: t("Sending...")
      });
      await sending.present();
      await store.dispatch("auth/login", form);
      sending.dismiss();
      if (store.state.auth.user) {
        router.push({ name: "Diary", replace: true });
      }
    };

    return {
      form,
      submit,
      eye,
      eyeOff,
      logInOutline,
      showPassword,
      switchShowPassword,
      errorMessage: computed(() => {
        const value = store.state.auth.error;
        if (!value) {
          return false;
        }
        if (!Array.isArray(value)) {
          return [value];
        }
        return value;
      }),
      router
    };
  }
};
</script>
<style scoped>
.password + ion-icon {
  display: none;
}
.password.has-focus + ion-icon,
.password.has-value + ion-icon {
  display: block;
}
.password + ion-icon {
  align-self: flex-end;
}
ion-item + ion-button,
ion-chip + ion-button {
  margin-top: 10px;
}
ion-chip ion-label {
  width: 100%;
}
</style>
